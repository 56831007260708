<template>
  <el-dialog :title=" '上传数据治理方法'" :close-on-click-modal="false" :visible.sync="visible">

    <div class="">
        <el-form label="文件：" size="mini">
            <el-upload class="upload-demo" action="#"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :on-change="fileChange"
                multiple :limit="10"
                :on-exceed="handleExceed"
                :file-list="fileList">
                <el-button size="samll" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">请上传jar包</div>
            </el-upload>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog()">取消</el-button>
            <el-button @click="uploadJar()">确定</el-button>
        </span>
  </div>
  </el-dialog>
</template>

<script>


export default {
  name:'Simple',
  components:{

  },
  data() {
    return {
      visible: false,
      fileList: [],
      
    
    };
  },
  created(){
    this.init();
  },
  activated() {
    this.init();
  },
  methods: {
    init() {
      this.visible=true;
    },
    
    //创建手动录入表
    createManualEntry(){
        
    },

    /**
     * 点击取消按钮
     */
    closeDialog(){
        this.fileList=[];
        this.visible=false;
    },

    /**
     * 点击确定按钮，上传jar包
     */
    uploadJar(){
        let formData=new FormData();
        this.fileList.forEach(file=>{
            formData.append('file',file.raw);
        });
        this.postRequest("/methodUpload/uoloadJar",formData).then(response=>{
            if(response&&response.status===200){
                this.visible=false;
            }
        }).then(error=>{
            
        })
    },


    handleRemove(file, fileList) {
        console.log(file, fileList);
      },
    handlePreview(file) {
        console.log(file);
    },
    handleExceed(files, fileList) {
        this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file, fileList) {
        return this.$confirm(`确定移除 ${ file.name }？`);
    },
      // 文件改变时
    fileChange(file, fileList) {
        this.fileList=fileList;
        this.fileList.append(file);
    },
  },
};
</script>
